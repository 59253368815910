import axios from 'axios';
import { FormEvent, useState, useEffect } from 'react';
import { ResponseCache } from '../../interfaces/ResponseCache';
import { obtainURLBackendClay } from '../../common/obtainURL';
import { useAppContext } from '../../common/appContext';
import {
  AppStateReducerActionTypes,
  EmpresasDictionary,
  bancosDictionary,
} from '../../common/reducer';
import {
  IonCol,
  IonButton,
  IonRow,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonCardContent,
  IonInput,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
} from '@ionic/react';
import { responseClayCuentas } from '../../interfaces/responseClayCuentas';
import { createHeader } from '../../common/auth';
import { arrowUndoOutline, duplicateOutline } from 'ionicons/icons';
let URL = obtainURLBackendClay();

const InversionesModuloCrear = () => {
  const defaultCache: ResponseCache = { cached: false, response: null };
  const [responseCache, setResponseCache] = useState(defaultCache);
  const [selectedTipo, setSelectedTipo] = useState('');
  const { dispatch: appDispatch } = useAppContext();
  const [tableRefreshPending, setTableRefreshPending] = useState(true);
  var [rowsTable, setRowsTable] = useState([]);
  const [tokenPSG, setTokenPSG] = useState('');
  const [asyncState, setAsyncState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  interface Empresa {
    realName: string;
    rut: string;
  }
  interface Banks {
    bankName: string;
    bankInternalId: string;
  }
  const empresasDefault: Empresa[] = [];
  const bancosDefault: Banks[] = [];
  const selectedEmpresaDefault: Empresa | null = null;
  const [selectedEmpresa, setSelectedEmpresa] = useState(selectedEmpresaDefault);
  const [empresas, setEmpresas] = useState(empresasDefault);
  const [bancos, setBancos] = useState(bancosDefault);
  const [accountTypeSelected, setAccountTypeSelected] = useState('INVERSION');

  useEffect(() => {
    const tokenPSG = JSON.parse('' + localStorage.getItem('user')).token;
    setTokenPSG(tokenPSG);
    listadoCuentas(tokenPSG);
    cargaListadoEmpresa(tokenPSG);
    cargaListadoBanco(tokenPSG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function listadoCuentas(token: string) {
    if (responseCache.cached) return;
    setTableRefreshPending(true);
    axios
      .get(URL + '/cuentas-bancarias/', createHeader(token))
      .then((response) => {
        setResponseCache({ cached: true, response: response });
        var rowsTable: any = [];
        response.data.data.response[1].forEach((cuenta: responseClayCuentas) => {
          if (cuenta.accountType === 'INVERSION') {
            rowsTable.push(cuenta);
          }
        });
        setTableRefreshPending(false);
        setRowsTable(rowsTable);
      })
      .catch((error) => {
        console.error('Error: ' + error);
        setResponseCache({ cached: true, response: null });
        setTableRefreshPending(false);
        tokenInvalido();
      });
  }

  function cargaListadoEmpresa(token: string) {
    if (responseCache.cached) return;
    setTableRefreshPending(true);
    let rows: any = [];
    axios
      .get(URL + '/empresas', createHeader(token))
      .then((response) => {
        const empresasDictionary: EmpresasDictionary = {};
        response.data.data.response[1].forEach((empresa: Empresa) => {
          rows.push({ rut: empresa.rut, realName: empresa.realName });
          empresasDictionary[empresa.rut] = empresa.realName;
        });
        setEmpresas(rows);

        appDispatch({
          type: AppStateReducerActionTypes.SAVE_EMPRESAS,
          empresas_payload: empresasDictionary,
        });
        setTableRefreshPending(false);
      })
      .catch((error) => {
        setTableRefreshPending(false);
        tokenInvalido();
      });
  }

  function cargaListadoBanco(token: string) {
    if (responseCache.cached) return;
    setTableRefreshPending(true);
    let rows: any = [];
    axios
      .get(URL + '/bancos', createHeader(token))
      .then((response) => {
        setResponseCache({ cached: true, response: response });
        const bancos: bancosDictionary = {};
        response.data.data.response[1].forEach((banco: Banks) => {
          rows.push({
            bankInternalId: banco.bankInternalId + ',' + banco.bankName,
            bankName: banco.bankName,
          });
          bancos[banco.bankInternalId] = banco.bankName;
        });
        setBancos(rows);

        appDispatch({
          type: AppStateReducerActionTypes.SAVE_BANCOS,
          bancos_payload: bancos,
        });
        setTableRefreshPending(false);
      })
      .catch((error) => {
        setTableRefreshPending(false);
        tokenInvalido();
      });
  }

  function tokenInvalido() {
    setTimeout(function () {
      console.log('cerrar sesion');
      localStorage.removeItem('user');
      localStorage.removeItem('screen');
      localStorage.removeItem('sapcredentials');
      appDispatch({ type: AppStateReducerActionTypes.LOGOUT });
    }, 4000);
  }

  function formSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let dataRequest: object;
    setErrorMessage('');
    const formData = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(formData.entries());
    console.log('🚀 ~ formSubmit ~ formDictionary:', formDictionary);
    let selectorBanco = formDictionary['selectBanca'] + '';
    let arrayDeCadenas = selectorBanco.split(',');

    dataRequest = {
      tipoCuenta: formDictionary['accountType'],
      tipoInversion: formDictionary['selectTipo'],
      empresaRut: formDictionary['selectEmpresa'],
      numeroCuenta: formDictionary['numero'],
      bancoId: arrayDeCadenas[0],
      moneda: formDictionary['selectMoneda'],
      numeroCuentaSap: formDictionary['accountNumberSap'],
      cuentaContableSap: formDictionary['accountantNumberSap'],
      sysCodeSap: formDictionary['syscodeSap'],
      sincronizarSaldos: formDictionary['sincronizarSaldos']==='on',
      sincronizarMovimientos: formDictionary['sincronizarMovimientos']==='on',
    };
    console.log('create-inversion:dataRequest', dataRequest);
    axios
      .post(URL + '/cuentas-bancarias', dataRequest, createHeader(tokenPSG))
      .then((response) => {
        console.log(response.data.data.response);
        appDispatch({ type: AppStateReducerActionTypes.SHOW_INVERSIONES });
      })
      .catch((error) => {
        console.error(
          ' Error en autorizacion, por favor vuelve a iniciar sesion: ' +
            error.message +
            ' - method: importData'
        );
        setTableRefreshPending(false);
        tokenInvalido();
      });
  }

  function back() {
    appDispatch({ type: AppStateReducerActionTypes.SHOW_INVERSIONES });
  }

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonRow class="ion-justify-content-between ion-align-items-center">
            <IonCol>
              <IonCardTitle className="bold" color="primary">
                Crear cuenta bancaria:
              </IonCardTitle>
            </IonCol>
            <IonButton onClick={back} color="primary" fill="solid" expand="block" size="small">
              <IonIcon slot="start" icon={arrowUndoOutline}></IonIcon>
              Regresar
            </IonButton>
          </IonRow>
        </IonCardHeader>
        <IonCardContent>
          <form onSubmit={formSubmit}>
            <IonRow class="ion-justify-content-between ion-align-items-center">
              <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                <IonItem>
                  <IonLabel position="floating">Tipo de cuenta:</IonLabel>
                  <IonSelect
                    interface="popover"
                    name="accountType"
                    onIonChange={(e) => setAccountTypeSelected(e.detail.value)}>
                    <IonSelectOption value="CORRIENTE">Corriente</IonSelectOption>
                    <IonSelectOption value="INVERSION">Inversión</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonButton expand="block" type="submit" disabled={asyncState} color="success">
                <IonIcon slot="start" icon={duplicateOutline}></IonIcon>
                Crear cuenta bancaria
              </IonButton>
            </IonRow>
            <IonRow class="ion-justify-content-between ion-align-items-center">
              {accountTypeSelected === 'INVERSION' ? (
                <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                  <IonItem>
                    <IonLabel position="floating">Tipo de inversión:</IonLabel>
                    <IonSelect interface="popover" name="selectTipo">
                      <IonSelectOption value={null}>Ninguna</IonSelectOption>
                      <IonSelectOption value="FONDO_MUTUO">FONDO MUTUO - FM</IonSelectOption>
                      <IonSelectOption value="DEPOSITO_PLAZO">
                        DEPOSITO A PLAZO - DP
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              ) : (
                <></>
              )}
              <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                <IonItem>
                  <IonLabel position="floating">Seleccione una moneda:</IonLabel>
                  <IonSelect interface="popover" name="selectMoneda">
                    <IonSelectOption value={null}>Ninguna</IonSelectOption>
                    <IonSelectOption value="CLP">Peso Chileno</IonSelectOption>
                    <IonSelectOption value="USD">Dolar</IonSelectOption>
                    <IonSelectOption value="EUR">EURO</IonSelectOption>
                    <IonSelectOption value="UF">Uni Fomento</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-between ion-align-items-center">
              <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                <IonItem>
                  <IonLabel position="floating">Seleccione un banco:</IonLabel>
                  <IonSelect interface="popover" name="selectBanca">
                    <IonSelectOption value={null}>Ninguna</IonSelectOption>
                    {bancos.map((banca) => (
                      <IonSelectOption value={banca.bankInternalId} key={banca.bankName}>
                        {banca.bankName}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                <IonItem>
                  <IonLabel position="floating">Seleccione una empresa:</IonLabel>
                  <IonSelect
                    interface="popover"
                    name="selectEmpresa"
                    disabled={empresas.length < 2}>
                    <IonSelectOption value={null}>Ninguna</IonSelectOption>
                    {empresas.map((empresa) => (
                      <IonSelectOption value={empresa.rut} key={empresa.rut}>
                        {empresa.realName}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-between ion-align-items-center">
              <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                <IonItem>
                  <IonLabel position="floating">Numero de Cuenta:</IonLabel>
                  <IonInput
                    placeholder="Numero de cuenta 000-000-00-0..."
                    type="text"
                    name="numero"
                    maxlength={20}></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                  <IonItem>
                    <IonLabel position="floating">Nro de Cuenta SAP:</IonLabel>
                    <IonInput
                      placeholder="Numero de cuenta 000-000-00-0..."
                      type="text"
                      name="accountNumberSap"
                      maxlength={20}></IonInput>
                  </IonItem>
                </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-between ion-align-items-center">
                <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                  <IonItem>
                    <IonLabel position="floating">Nro de Cuenta Contable SAP:</IonLabel>
                    <IonInput
                      placeholder="Numero de cuenta SAP 000-000-00-0..."
                      type="text"
                      name="accountantNumberSap"
                      maxlength={8}></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                  <IonItem>
                    <IonLabel position="floating">SysCode SAP:</IonLabel>
                    <IonInput
                      placeholder="SysCode SAP 000-000-00-0..."
                      type="text"
                      name="syscodeSap"
                      maxlength={15}></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow class="ion-justify-content-between ion-align-items-center">
                <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                  <IonItem>
                    <IonCheckbox slot="end" name="sincronizarSaldos"></IonCheckbox>
                    <IonLabel>Sincronizar Saldos:</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                  <IonItem>
                    <IonCheckbox slot="end" name="sincronizarMovimientos"></IonCheckbox>
                    <IonLabel>Sincronizar Movimientos:</IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
          </form>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default InversionesModuloCrear;
