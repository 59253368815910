import { useState, useEffect, FormEvent } from 'react';
import { createHeader } from '../../common/auth';
import { obtainURLBackendClay } from '../../common/obtainURL';
import { useAppContext } from '../../common/appContext';
import {
  AppStateReducerActionTypes,
  EmpresasDictionary,
  bancosDictionary,
} from '../../common/reducer';
import {
  IonCol,
  IonButton,
  IonRow,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonCardContent,
  IonInput,
  IonCardHeader,
  IonCardTitle,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonLoading,
  IonToast,
  IonText,
  IonList,
  IonCheckbox,
} from '@ionic/react';
import {
  trash,
  cloudUploadOutline,
  arrowUndoOutline,
  checkmarkDoneOutline,
  duplicateOutline,
  fileTrayOutline,
  createOutline,
  alertCircleOutline,
} from 'ionicons/icons';
import axios from 'axios';
import {
  BalanceListInvestmentAccount,
  DetailsInvestmentAccount,
} from '../../interfaces/investmentAccounts';
let URL = obtainURLBackendClay();



const AccountDetails = () => {
  const { state: appState, dispatch: appDispatch } = useAppContext();
  interface Empresa {
    realName: string;
    rut: string;
  }
  interface Banks {
    bankName: string;
    bankInternalId: string;
  }
  
  const empresasDefault: Empresa[] = [];
  const bancosDefault: Banks[] = [];
  const [addNewBalance, setAddNewBalance] = useState({ type: 'create', value: false });
  const [empresas, setEmpresas] = useState(empresasDefault);
  const [bancos, setBancos] = useState(bancosDefault);
  const [formData, setFormData] = useState<DetailsInvestmentAccount>(
    appState.investmentAccountDetails
  );
  const [formBalance, setFormBalance] = useState('');
  const [balanceData, setBalanceData] = useState<BalanceListInvestmentAccount[]>(
    appState.investmentAccountDetails.balanceList!
  );
  const [isAlertOpen, setAlertIsOpen] = useState({ open: false, action: '' });
  const [tokenPSG, setTokenPSG] = useState('');
  const [loadingAccountDetails, setAccountDetails] = useState({ type: '', value: false });
  const [loadingBalance, setLoadingBalance] = useState({ type: '', value: false });
  const [toastUpdate, setToastUpdate] = useState(false);
  const [toastBalance, setToastBalance] = useState({ type: '', value: false });
  const [alertBalance, setAlertBalance] = useState({ action: '', reportedDate: '', open: false });
  const [reportedDate, setReportedDate] = useState('');
  const [autoSyncBalance] = useState({ autoSyncBalance: formData.autoSyncBalance });
  const [autoSyncTransactions] = useState({ autoSyncTransactions: formData.autoSyncTransactions });
  const [validated] = useState({validated: formData.validated});


  const handleAutoSyncBalance = (value: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      autoSyncBalance: value,
    }));
  };

  const handleAutoSyncTransactions = (value: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      autoSyncTransactions: value,
    }));
  };
  
  const handleValidated = (value: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      validated: value,
    }));
  };

  useEffect(() => {
    console.log('DATA QUE LLEGA:', appState.investmentAccountDetails);
    const token = JSON.parse('' + localStorage.getItem('user')).token;
    setTokenPSG(token);
    fetchCompanies(token);
    fetchBanks(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCompanies = async (token: string) => {
    try {
      const response = await axios.get(`${URL}/empresas`, createHeader(token));
      const empresasDictionary: EmpresasDictionary = {};
      const companies: Empresa[] = response.data.data.response[1].map((empresa: Empresa) => {
        empresasDictionary[empresa.rut] = empresa.realName;
        return { rut: empresa.rut, realName: empresa.realName };
      });
      setEmpresas(companies);
    } catch (error) {
      console.error('ERROR AL TRAER EMPRESAS:', error);
    }
  };

  const fetchBanks = async (token: string) => {
    try {
      const response = await axios.get(`${URL}/bancos`, createHeader(token));
      const bancos: bancosDictionary = {};
      const rows: Banks[] = response.data.data.response[1].map((banco: Banks) => {
        bancos[banco.bankInternalId] = banco.bankName;
        return { bankInternalId: banco.bankInternalId, bankName: banco.bankName };
      });
      setBancos(rows);
    } catch (error) {
      console.error('ERROR AL TRAER BANCOS:', error);
    }
  };

  const handleInputChange_one = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKeyPress = (e: any) => {
    // Evitar que se ingresen letras
    const soloNumeros = /^[0-9\b]+$/;
    if (!soloNumeros.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputChange_two = (e: any) => {
    e.preventDefault();
    // Filtrar el valor ingresado para permitir solo números
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    setFormBalance(newValue);
  };

  const handleFormChange = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(form.entries());
    console.log('formDictionary',formDictionary);
    console.log('formData:autoSyncBalance',formData.autoSyncBalance);
    const updateAccountObject = {
      tipoCuenta: formData.accountType,
      tipoInversion:
        formDictionary['investmentType'] === undefined ? null : formDictionary['investmentType'],
      empresaRut: formDictionary['empresaRut'],
      numeroCuenta: formDictionary['numeroCuenta'],
      bancoId: formDictionary['bankId'],
      moneda: formDictionary['currency'],
      numeroCuentaSap:
        formDictionary['numeroCuentaSap'] === undefined ? null : formDictionary['numeroCuentaSap'],
      cuentaContableSap:
        formDictionary['cuentaContableSap'] === undefined
          ? null
          : formDictionary['cuentaContableSap'],
      sysCodeSap: formDictionary['sysCodeSap'] === undefined ? null : formDictionary['sysCodeSap'],
      sincronizarSaldos: formDictionary['sincronizarSaldos']==='on',
      sincronizarMovimientos: formDictionary['sincronizarMovimientos']==='on',
      validado: formDictionary['validado']==='on'
    };
    console.log('🚀 ~ handleFormChange ~ updateAccountObject:', updateAccountObject);
    updateAccount(updateAccountObject, 'update');
  };

  const updateAccount = async (formDataUpdate: object, type: string) => {
    try {
      setAccountDetails({ type: type, value: true });
      const response = await axios.put(
        `${URL}/cuentas-bancarias/${formData.accountNumber}`,
        formDataUpdate,
        {
          headers: {
            Authorization: `Bearer ${tokenPSG}`,
          },
        }
      );
      console.log('DATA DEL RESPONSE LUEGO DE ACTUALIZAR:', response.data.data.response);
      if (response.data.data.response) {
        setAccountDetails({ type: type, value: false });
        setTimeout(() => {
          setToastUpdate(true);
        }, 1000);
      }
    } catch (error) {
      console.error('ERROR AL ACTUALIZAR CUENTA', error);
    }
  };

  const deleteAccount = async (type: string) => {
    try {
      setAccountDetails({ type: type, value: true });
      const response = await axios.delete(
        `${URL}/cuentas-bancarias/${formData.accountNumber}`,
        createHeader(tokenPSG)
      );
      console.log('DATA DEL RESPONSE LUEGO DE ELIMINAR:', response.data.data.response);
      if (response.data.data.response) {
        appDispatch({ type: AppStateReducerActionTypes.SHOW_INVERSIONES });
        setAccountDetails({ type: type, value: false });
      }
    } catch (error) {
      console.error('ERROR EN ELIMINAR CUENTA', error);
    }
  };

  const obtainActualDate = () => {
    const actualDate = new Date();
    const yyyy = actualDate.getFullYear();
    const mm = String(actualDate.getMonth() + 1).padStart(2, '0'); // Agrega 1 al mes, ya que los meses se indexan desde 0
    const dd = String(actualDate.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const createBalance = async (accountNumber: string) => {
    try {
      setLoadingBalance({ type: 'create', value: true });
      setToastBalance({ type: 'create', value: false });
      if (formBalance !== '' && Number(formBalance) > 0) {
        const response = await axios.post(
          `${URL}/cuentas-bancarias/${accountNumber}/saldos`,
          {
            numeroCuenta: formData.accountNumber,
            fechaReportada: obtainActualDate(),
            saldo: Number(formBalance),
          },
          {
            headers: {
              Authorization: `Bearer ${tokenPSG}`,
            },
          }
        );
        if (response.data.data.response[1].success) {
          setFormBalance(''); // Seteo en string vacio el state de balance
          setAddNewBalance({ type: '', value: false }); //Oculto de nuevo el input de crear saldo
          setLoadingBalance({ type: '', value: false }); //Oculto el loader
          setToastBalance({ type: 'create', value: true }); //Muestro el toast success
          callBalances(); //LLamo a la funcion que me lista los saldos de nuevo
        } else {
          setFormBalance(''); // Seteo en string vacio el state de balance
          setAddNewBalance({ type: '', value: false }); //Oculto de nuevo el input de crear saldo
          setLoadingBalance({ type: '', value: false }); //Oculto el loader
          setToastBalance({ type: 'error', value: true }); //Muestro el toast error
        }
      } else {
        setLoadingBalance({ type: '', value: false }); //Oculto el loader
        setToastBalance({ type: 'empty', value: true });
      }
    } catch (error) {
      console.error('ERROR AL CREAR SALDO NUEVO:', error);
    }
  };

  const deleteAlert = async (reportedDate: string) => {
    setAlertBalance({ action: 'delete', reportedDate: reportedDate, open: true });
  };

  const deleteBalance = async (reportedDate: string) => {
    try {
      setLoadingBalance({ type: 'delete', value: true });
      setToastBalance({ type: 'delete', value: false });
      const response = await axios.delete(
        `${URL}/cuentas-bancarias/${formData.accountNumber}/saldos`,
        {
          headers: {
            Authorization: `Bearer ${tokenPSG}`,
          },
          data: {
            numeroCuenta: formData.accountNumber,
            fechaReportada: reportedDate,
          },
        }
      );
      if (response.data.data.response[1].success) {
        setLoadingBalance({ type: '', value: false }); //Oculto el loader
        setToastBalance({ type: 'delete', value: true }); //Muestro el toast success
        callBalances(); //LLamo a la funcion que me lista los saldos de nuevo
      } else {
        console.log('Error al borrar saldo');
      }
    } catch (error) {
      console.error('ERROR AL ELIMINAR SALDO:', error);
    }
  };

  const beforeUpdateBalance = (oldBalance: string, reportedDate: string) => {
    console.log('Balance que ya trae:', oldBalance);
    setFormBalance(oldBalance);
    setReportedDate(reportedDate);
    setAddNewBalance({ type: 'update', value: true });
  };

  const updateBalance = async () => {
    try {
      setLoadingBalance({ type: 'update', value: true });
      setToastBalance({ type: 'update', value: false });
      if (formBalance !== '' && Number(formBalance) > 0) {
        const response = await axios.put(
          `${URL}/cuentas-bancarias/${formData.accountNumber}/saldos`,
          {
            numeroCuenta: formData.accountNumber,
            fechaReportada: reportedDate,
            saldo: Number(formBalance),
          },
          {
            headers: {
              Authorization: `Bearer ${tokenPSG}`,
            },
          }
        );
        if (response.data.data.response[1].success) {
          setFormBalance(''); // Seteo en string vacio el state de balance
          setAddNewBalance({ type: '', value: false }); //Oculto de nuevo el input de actualizar saldo
          setLoadingBalance({ type: '', value: false }); //Oculto el loader
          setToastBalance({ type: 'update', value: true }); //Muestro el toast success
          callBalances(); //LLamo a la funcion que me lista los saldos de nuevo
        } else {
          setFormBalance(''); // Seteo en string vacio el state de balance
          setLoadingBalance({ type: '', value: false }); //Oculto el loader
          setAddNewBalance({ type: '', value: false }); //Oculto de nuevo el input de actualizar saldo
        }
      } else {
        setLoadingBalance({ type: '', value: false });
        setToastBalance({ type: 'empty', value: true });
      }
    } catch (error) {
      console.error('ERROR AL ACTUALIZAR SALDO:', error);
    }
  };

  const callBalances = async () => {
    try {
      const balances = await axios.get(
        `${URL}/cuentas-bancarias/${formData.accountNumber}/saldos`,
        createHeader(tokenPSG)
      );
      setBalanceData(balances.data.data.response[1]);
    } catch (error) {
      console.error('ERROR AL LLAMAR BALANCES DE SALDOS POR NUMERO DE CUENTA:', error);
    }
  };

  const back = () => {
    appDispatch({ type: AppStateReducerActionTypes.SHOW_INVERSIONES });
  };

  return (
    <>
      <IonToast
        isOpen={toastUpdate}
        message="¡Data de la cuenta actualizada con éxito!"
        icon={checkmarkDoneOutline}
        duration={3000}
        position="bottom"
        color="success"></IonToast>
      <IonToast
        isOpen={toastBalance.value}
        message={
          toastBalance.type === 'create'
            ? '¡Nuevo saldo creado con éxito!'
            : toastBalance.type === 'update'
            ? '¡Saldo actualizado con éxito!'
            : toastBalance.type === 'delete'
            ? '¡Saldo eliminado con éxito!'
            : toastBalance.type === 'empty'
            ? '¡Data del balance vacia!'
            : '¡La fecha de saldo ya existe, solo agregar un saldo al día!'
        }
        icon={
          toastBalance.type === 'error' || toastBalance.type === 'empty'
            ? alertCircleOutline
            : checkmarkDoneOutline
        }
        duration={3000}
        position="bottom"
        color={
          toastBalance.type === 'error' || toastBalance.type === 'empty' ? 'danger' : 'success'
        }></IonToast>
      <IonLoading
        isOpen={loadingAccountDetails.value}
        message={
          loadingAccountDetails.type === 'delete' ? 'Eliminando cuenta...' : 'Actualizando data...'
        }
      />
      <IonLoading
        isOpen={loadingBalance.value}
        message={
          loadingBalance.type === 'create'
            ? 'Creando nuevo saldo...'
            : loadingBalance.type === 'update'
            ? 'Actualizando saldo...'
            : 'Eliminando saldo...'
        }
      />
      <IonCard>
        <IonCardHeader>
          <IonRow>
            <IonCol>
              <IonCardTitle className="bold" color="primary">
                Detalles de la cuenta bancaria:
              </IonCardTitle>
            </IonCol>
            <IonButton onClick={back} color="primary" fill="solid" expand="block" size="small">
              <IonIcon slot="start" icon={arrowUndoOutline}></IonIcon>
              Regresar
            </IonButton>
          </IonRow>
        </IonCardHeader>
        <IonCardContent>
          <form onSubmit={handleFormChange}>
            <IonRow class="ion-align-items-center ion-justify-content-start">
              <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                <IonItem>
                  <IonLabel position="stacked">Número de cuenta:</IonLabel>
                  <IonInput
                    value={formData?.accountNumber}
                    type="text"
                    name="numeroCuenta"></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                <IonItem>
                  <IonLabel position="stacked">Moneda:</IonLabel>
                  <IonSelect
                    value={
                      formData?.currency === 'CLP'
                        ? 'CLP'
                        : formData?.currency === 'USD'
                        ? 'USD'
                        : 'EUR'
                    }
                    interface="popover"
                    name="currency"
                    onIonChange={(e) => handleInputChange_one(e)}>
                    <IonSelectOption value={'CLP'}>CLP</IonSelectOption>
                    <IonSelectOption value={'USD'}>USD</IonSelectOption>
                    <IonSelectOption value={'EUR'}>EUR</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              {formData?.accountType === 'INVERSION' ? (
                <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                  <IonItem>
                    <IonLabel position="stacked">Tipo de inversión:</IonLabel>
                    <IonSelect
                      value={
                        formData?.investmentType === 'FONDO_MUTUO'
                          ? 'FONDO_MUTUO'
                          : 'DEPOSITO_PLAZO'
                      }
                      interface="popover"
                      name="investmentType"
                      onIonChange={(e) => handleInputChange_one(e)}>
                      <IonSelectOption value={'FONDO_MUTUO'}>Fondos mutuos</IonSelectOption>
                      <IonSelectOption value={'DEPOSITO_PLAZO'}>Depósito a plazo</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              ) : (
                <></>
              )}
            </IonRow>
            <IonRow class="ion-align-items-center ion-justify-content-center ion-margin-bottom">
              <IonCol size="12" sizeMd="12" sizeLg="3" sizeXl="3">
                <IonItem>
                  <IonLabel position="stacked">Tipo de cuenta:</IonLabel>
                  <IonSelect
                    disabled={true}
                    value={formData?.accountType}
                    interface="popover"
                    name="tipoCuenta">
                    <IonSelectOption value={'INVERSION'}>Inversión</IonSelectOption>
                    <IonSelectOption value={'CORRIENTE'}>Corriente</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="12" sizeLg="6" sizeXl="6">
                <IonItem>
                  <IonLabel position="stacked">Empresa:</IonLabel>
                  <IonSelect
                    value={empresas.find((empresa) => empresa.rut === formData?.rut)?.rut}
                    interface="popover"
                    name="empresaRut"
                    onIonChange={(e) => handleInputChange_one(e)}>
                    <IonSelectOption value={null}>Ninguna</IonSelectOption>
                    {empresas.map((empresa) => (
                      <IonSelectOption value={empresa.rut} key={empresa.rut}>
                        {empresa.realName}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="12" sizeLg="3" sizeXl="3">
                <IonItem>
                  <IonLabel position="stacked">Banco:</IonLabel>
                  <IonSelect
                    value={
                      bancos.find((banco) => banco.bankInternalId === formData?.bankId)
                        ?.bankInternalId
                    }
                    interface="popover"
                    name="bankId"
                    onIonChange={(e) => handleInputChange_one(e)}>
                    <IonSelectOption value={null}>Ninguna</IonSelectOption>
                    {bancos.map((banca) => (
                      <IonSelectOption value={banca.bankInternalId} key={banca.bankName}>
                        {banca.bankName}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="ion-align-items-center">
                <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                  <IonItem>
                    <IonLabel position="stacked">Número de cuenta SAP:</IonLabel>
                    <IonInput
                      name="numeroCuentaSap"
                      value={
                        formData?.sapAccountNumber === ''
                          ? 'Sin información'
                          : formData?.sapAccountNumber
                      }
                      type="text"></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                  <IonItem>
                    <IonLabel position="stacked">Número de cuenta contable SAP:</IonLabel>
                    <IonInput
                      name="cuentaContableSap"
                      value={
                        formData?.sapSheetAccount === ''
                          ? 'Sin información'
                          : formData?.sapSheetAccount
                      }
                      type="text"></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                  <IonItem>
                    <IonLabel position="stacked">SysCode de SAP:</IonLabel>
                    <IonInput
                      name="sysCodeSap"
                      value={formData?.sapSysCode === '' ? 'Sin información' : formData?.sapSysCode}
                      type="text"></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
            <IonRow>
              <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                <IonItem>
                  <IonCheckbox
                    slot="end"
                    name="sincronizarSaldos"
                    checked={formData.autoSyncBalance}
                    onIonChange={(e) => handleAutoSyncBalance(e.detail.checked)}></IonCheckbox>
                  <IonLabel>Sincronizar Saldos:</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                <IonItem>
                  <IonCheckbox
                    slot="end"
                    name="sincronizarMovimientos"
                    checked={formData.autoSyncTransactions}
                    onIonChange={(e) => handleAutoSyncTransactions(e.detail.checked)}></IonCheckbox>
                  <IonLabel>Sincronizar Movimientos:</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                <IonItem>
                  <IonCheckbox
                    slot="end"
                    name="validado"
                    checked={formData.validated}
                    onIonChange={(e) => handleValidated(e.detail.checked)}></IonCheckbox>
                  <IonLabel>La cuenta está validada:</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="ion-align-items-center ion-justify-content-end">
              <IonButton
                onClick={() => setAlertIsOpen({ open: true, action: 'delete' })}
                expand="block"
                color="danger"
                size="small">
                <IonIcon slot="start" icon={trash}></IonIcon>
                Eliminar Cuenta
              </IonButton>
              <IonButton type="submit" expand="block" color="success" size="small">
                <IonIcon slot="start" icon={cloudUploadOutline}></IonIcon>
                Actualizar datos
              </IonButton>
            </IonRow>
          </form>
        </IonCardContent>
      </IonCard>
      {/* <IonCard>
        <IonCardHeader>
          <IonRow>
            <IonCol>
              <IonCardTitle className="bold" color="primary">
                Saldos bancarios:
              </IonCardTitle>
            </IonCol>
            {addNewBalance.value === false ? (
              <IonButton
                onClick={() => setAddNewBalance({ type: 'create', value: true })}
                color="primary"
                fill="solid"
                expand="block"
                size="small">
                <IonIcon slot="start" icon={duplicateOutline}></IonIcon>
                Crear nuevo saldo
              </IonButton>
            ) : (
              <IonButton
                onClick={() => setAddNewBalance({ type: '', value: false })}
                color="primary"
                fill="solid"
                expand="block"
                size="small">
                <IonIcon slot="start" icon={arrowUndoOutline}></IonIcon>
                Regresar
              </IonButton>
            )}
          </IonRow>
        </IonCardHeader>
        <IonCardContent>
          {addNewBalance.value ? (
            <IonRow class="ion-align-items-center ion-justify-content-start ion-margin-bottom">
              <IonCol size="12" sizeMd="12" sizeLg="4" sizeXl="4">
                <IonItem>
                  <IonLabel position="floating">
                    {addNewBalance.type === 'create'
                      ? 'Ingrese el nuevo saldo:'
                      : 'Modifique el saldo:'}
                  </IonLabel>
                  <IonInput
                    onKeyPress={(e) => handleKeyPress(e)}
                    inputmode="numeric"
                    value={formBalance}
                    onIonChange={(e) => handleInputChange_two(e)}
                    type="text"></IonInput>
                </IonItem>
              </IonCol>
              <IonButton
                onClick={
                  addNewBalance.type === 'create'
                    ? () => createBalance(formData.accountNumber!)
                    : () => updateBalance()
                }
                color="primary"
                fill="outline"
                expand="block"
                size="small">
                <IonIcon slot="start" icon={duplicateOutline}></IonIcon>
                {addNewBalance.type === 'create' ? 'Guardar' : 'Actualizar'}
              </IonButton>
            </IonRow>
          ) : (
            <></>
          )}
          {balanceData?.length! === 0 ? (
            <IonItem>
              <IonIcon color="danger" icon={fileTrayOutline} slot="end"></IonIcon>
              <IonLabel className="bold">No hay saldos disponibles</IonLabel>
            </IonItem>
          ) : (
            <>
              <IonRow class="ion-align-items-center ion-justify-content-start">
                <IonCol size="12" sizeLg="2">
                  <IonItem class="ion-no-margin ion-no-padding">
                    <IonLabel color="primary" className="bold">
                      Saldo
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeLg="2">
                  <IonItem>
                    <IonLabel color="primary" className="bold">
                      Moneda
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeLg="2">
                  <IonItem>
                    <IonLabel color="primary" className="bold">
                      Fecha
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeLg="3">
                  <IonItem>
                    <IonLabel color="primary" className="bold">
                      Acciónes
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonList>
                {balanceData?.map((balance, index) => (
                  <IonRow class="ion-align-items-center ion-justify-content-start" key={index}>
                    <IonCol size="12" sizeLg="3" sizeXl="2">
                      <IonItem>
                        <IonLabel>{balance.balanceInHand}</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeLg="3" sizeXl="2">
                      <IonItem>
                        <IonText>{balance.currency}</IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeLg="3" sizeXl="2" class="ion-text-center">
                      <IonItem>
                        <IonText>{balance.reportedDate}</IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeLg="3" sizeXl="3" class="ion-text-center">
                      <IonRow>
                        <IonButton
                          onClick={() =>
                            beforeUpdateBalance(balance.balanceInHand, balance.reportedDate)
                          }
                          color="primary"
                          size="small"
                          expand="block"
                          fill="clear">
                          <IonIcon color="primary" slot="icon-only" icon={createOutline}></IonIcon>
                        </IonButton>
                        <IonButton
                          onClick={() => deleteAlert(balance.reportedDate)}
                          color="danger"
                          size="small"
                          expand="block"
                          fill="clear">
                          <IonIcon color="danger" slot="icon-only" icon={trash}></IonIcon>
                        </IonButton>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                ))}
              </IonList>
            </>
          )}
        </IonCardContent>
      </IonCard> */}
      <IonAlert
        isOpen={isAlertOpen.open}
        header={isAlertOpen.action === 'delete' ? 'Eliminar cuenta' : 'Actualizar datos'}
        message={
          isAlertOpen.action === 'delete'
            ? '¿Estas seguro de eliminar esta cuenta bancaria?'
            : '¡Estas a punto de actualizar los datos de esta cuenta bancaria!'
        }
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              setAlertIsOpen({ open: false, action: '' });
            },
          },
          {
            text: 'Confirmar',
            role: 'confirm',
            handler: () => {
              isAlertOpen.action === 'delete' && deleteAccount('delete');
              setAlertIsOpen({ open: false, action: '' });
            },
          },
        ]}
        onDidDismiss={() => setAlertIsOpen({ open: false, action: '' })}></IonAlert>
      <IonAlert
        isOpen={alertBalance.open}
        header="Eliminar saldo"
        message="¿Estas seguro de eliminar este saldo?"
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              setAlertBalance({ open: false, reportedDate: '', action: '' });
            },
          },
          {
            text: 'Confirmar',
            role: 'confirm',
            handler: () => {
              setAlertBalance({ open: false, reportedDate: '', action: '' });
              deleteBalance(alertBalance.reportedDate);
            },
          },
        ]}
        onDidDismiss={() => setAlertIsOpen({ open: false, action: '' })}></IonAlert>
    </>
  );
};
export default AccountDetails;
