import React from 'react';
import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes, Permission } from '../../common/reducer';
//import CippSelect from './cipp-select-menu';
import FincloudSelect from './fincloud-select-menu';
import BukSelect from './buk-select-menu';
import EfectivoEquivalenteSelect from './efectivo-equivalentes-select-menu';
import {
IonMenu,
useIonAlert,
IonContent,
IonItem,
IonIcon,
IonList,
} from '@ionic/react';
import { IonAccordion, IonAccordionGroup, IonLabel } from '@ionic/react';
import { logOutOutline } from 'ionicons/icons';
import UserSelect from './user-select-menu';

const Menu = () => {
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { state: appState, dispatch: stateDispatch } = useAppContext();
const [presentAlert] = useIonAlert();
React.useEffect(() => { });

function logout() {
stateDispatch({ type: AppStateReducerActionTypes.LOGOUT });
}

if (!appState.permissions || !appState) {
stateDispatch({ type: AppStateReducerActionTypes.LOGOUT });
}

const admin = appState.permissions.includes(Permission.ADMIN);
const fincloud = appState.permissions.includes(Permission.FINCLOUD) || admin;
const buk = appState.permissions.includes(Permission.BUK) || admin;
const user = appState.permissions.includes(Permission.CREDENCIALES_SAP) || admin;
const finance = appState.permissions.includes(Permission.FINANZAS) || admin;


const clickableStyle = {
  cursor: 'pointer'
};

return (
<>
  <IonMenu contentId='main'  >
    <IonContent className='ion-no-padding' color='primary'>
      <IonItem className='ion-padding ion-hide-md-down' color='primary'>
        <img src="/Logo-PSG-Fondo-Azul.png" alt="Grupo PSG" style={{ maxWidth: "50%" }} />
      </IonItem>
      <IonItem className='ion-padding ion-hide-md-up' color='primary'>
        <img src="/Logo-PSG-Fondo-Azul.png" alt="Grupo PSG" style={{ maxWidth: "50%" }} />
      </IonItem>

      <IonList class='ion-no-padding'>
        {/*<CippSelect /> */}
        <IonItem button lines='none' className='ion-hide-md-up' color='primary' onClick={()=>
          presentAlert({
            header: '¿Esta seguro que quiere salir de la sesión?',
            buttons: [{
                text: 'No',
                role: 'cancel',
              },
              {
                text: 'Si',
                role: 'confirm',
                handler: () => {
                  logout();
                },
              },
            ],
          })
          } >
          <IonIcon slot='start' size='large' color='white' icon={logOutOutline}></IonIcon>
          Cerrar sesión
        </IonItem>
        {/* {!fincloud && !buk && !admin ? (
        <IonItem lines = 'none' button color = "dark" onClick = { () =>
          presentAlert({
            header: '¿Esta seguro que quiere salir de la sesión?',
            buttons: [{
                text: 'No',
                role: 'cancel',
                // handler: () => {
                // setHandlerMessage('Alert canceled');
                // },
              },
              {
                text: 'Si',
                role: 'confirm',
                handler: () => {
                  logout();
                },
              },
            ],
          })
          }> 
          <IonIcon slot='start' size='large' color='white' icon={logOutOutline}></IonIcon>
          Salir
        </IonItem>
        ) : (
        <></>
        )} */}
      </IonList>

      <IonAccordionGroup color='primary'>
        {fincloud || buk ? 
          <IonAccordion value="first" >
            <IonItem slot="header" color='primary'>
              <IonLabel>CONTABILIDAD</IonLabel>
            </IonItem>
            <div  slot="content">
              <IonList class='ion-no-padding'>
              {fincloud ? <div style={clickableStyle}><FincloudSelect /></div> : null}
              {buk ? <div style={clickableStyle}><BukSelect /></div> : null}
              </IonList>
            </div>
          </IonAccordion> 
        : <></>}

         {finance ? 
          <IonAccordion value="second">
            <IonItem slot="header" color='primary'>
              <IonLabel>FINANZAS</IonLabel>
            </IonItem>
            <div  slot="content">
              <IonList class='ion-no-padding'>
              {finance ? <div style={clickableStyle}><EfectivoEquivalenteSelect /></div> : <></>}
              </IonList>
            </div>
          </IonAccordion>
         : <></> }
      </IonAccordionGroup>
      <IonList class='ion-no-padding'>
          {admin || user ? <div style={clickableStyle}><UserSelect /></div> : null}
            </IonList>
    </IonContent>
  </IonMenu>
</>
);
};

export default Menu;