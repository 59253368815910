import axios from 'axios';
import { FormEvent, useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { obtainURLBackendClay } from '../../common/obtainURL';
import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes } from '../../common/reducer';
import{IonCol,IonButton, IonRow,IonCard,IonIcon,IonItem,IonLabel, IonInput, IonCardHeader, IonCardTitle, IonCardContent, IonLoading, IonAlert,}from '@ionic/react';
import { responseClayCuentas } from '../../interfaces/responseClayCuentas';
import { createHeader } from '../../common/auth';
import {
  search,
  createOutline,
  duplicateOutline,
} from 'ionicons/icons';

let URL = obtainURLBackendClay();

    const SaldodeInversionModulo = () => {
    const { dispatch: appDispatch } = useAppContext();
    const [rowsTable, setRowsTable] = useState([]);
    const [accountNumber, setAccountNumber] = useState('')
    const [loadingAccountList, setLoadingAccountList] = useState(true)
    const [loadingAccountDetails, setLoadingAccountDetails] = useState(false)
    const [isAlertOpen, setAlertIsOpen] = useState(false);
    const [tokenPSG, setTokenPSG] = useState('')
    const columns: TableColumn < responseClayCuentas > [] = [
      {name: 'N° de cuenta', selector: (row) => row.accountNumber},
      {name: 'Rut', selector: (row) => row.rut},
      {name: 'Razon Social', selector: (row) => row.companyShortName},
      {name: 'Moneda', selector: (row) => row.currency},
      {name: 'Banco', selector: (row) => row.bankAlias},
      {name: 'Inversión', selector: (row) => row.investmentType, width: '150px'},
      {name: "Acción", cell: (row) => (<IonButton size="small" expand="block" fill='outline' onClick={(e) => callAccountsDetails(e,  row.accountNumber)}><IonIcon slot="start" icon={createOutline}></IonIcon>Editar Saldos</IonButton>)}
    ];

    useEffect(() => {
      const token = JSON.parse(""+localStorage.getItem("user") ).token;
      setTokenPSG(token)
      listAccounts(token);
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const callAccountsDetails = async (e: any, accountNumber: any) => {
        e.preventDefault();
        try {
          setLoadingAccountDetails(true);
          const details = await axios.get(`${URL}/cuentas-bancarias/${accountNumber}`, createHeader(tokenPSG));
          appDispatch({ type: AppStateReducerActionTypes.CALL_INVERSION_DETAILS, investmentAccountDetails_payload: details.data.data.response });
          appDispatch({ type: AppStateReducerActionTypes.CUENTAS_INVERSIONES });
          setLoadingAccountDetails(false);
        } catch (error) {
          console.error('ERROR AL PEDIR DETALLES DE LA CUENTA:', error);
          setLoadingAccountDetails(false);
        }
      };


    
    
    const listAccounts = async (token: string) => {
        try {
          const response = await axios.get(`${URL}/cuentas-bancarias/`, createHeader(token));
          const cuentas = response.data.data.response[1];
      
          // Filtrar las cuentas que tienen accountType "INVERSION"
          const cuentasInversion = cuentas.filter((cuenta: any) => cuenta.accountType === "INVERSION");
      
          console.log("las cuentas de inversion", cuentasInversion);
          setLoadingAccountList(false);
          setRowsTable(cuentasInversion);
        } catch (error) {
          invalidToken();
        }
      };
      
    const invalidToken =() => {
      setTimeout(function(){
        console.log("cerrar sesion");
        localStorage.removeItem('user');
        localStorage.removeItem('screen');
        localStorage.removeItem('sapcredentials');
        appDispatch({ type: AppStateReducerActionTypes.LOGOUT });
      }, 4000);
    }

    const addUser = () => {
      //Redirecciono a la view de creacion de cuenta de inversión
      appDispatch({ type: AppStateReducerActionTypes.ADD_ACCOUNT_INVERSIONES });
    }

    const handleAccountNumber = (e: any) => {
        setAccountNumber(e.target.value)
    }

    const searchBySpecificNumber = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        setLoadingAccountDetails(true);
        const details = await axios.get(`${URL}/cuentas-bancarias/${accountNumber}`, createHeader(tokenPSG))
        if (Object.keys(details.data.data.response).length > 0 && accountNumber !== '') {
          appDispatch({type: AppStateReducerActionTypes.CALL_INVERSION_DETAILS, investmentAccountDetails_payload: details.data.data.response})
          appDispatch({ type: AppStateReducerActionTypes.ACCOUNT_INVERSIONES });
          setLoadingAccountDetails(false);
        } else {
          setLoadingAccountDetails(false);
          setAlertIsOpen(true)
        }
      } catch (error) {
        console.error('ERROR AL PEDIR DETALLES DE LA CUENTA:', error)
      }
    }

    return (
    <>
      <IonLoading isOpen={loadingAccountList}  message="Cargando cuentas bancarias..." />
      <IonLoading isOpen={loadingAccountDetails}  message="Cargando Detalles de la cuenta seleccionada..." />
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className='bold' color='primary'>Saldos de Inversion</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
        <form onSubmit={searchBySpecificNumber}>
          <IonRow class='ion-align-items-center ion-justify-content-start'>
            <IonCol size='12' sizeMd='12' sizeLg='5' sizeXl='4'>
             <IonItem>
								<IonLabel position='floating'>Ingrese un número de cuenta:</IonLabel>
                <IonInput value={accountNumber} onIonChange={(e)=> handleAccountNumber(e)} type='text' name='cuentaBuscar'></IonInput>
						 </IonItem>
            </IonCol>
            <IonCol size='12' sizeMd='12' sizeLg='3' sizeXl='2'>
              <IonButton fill='solid' type='submit' size='default' color='primary' expand='block'>
                <IonIcon slot='start' icon={search}></IonIcon>
                Buscar Cuenta
              </IonButton>
            </IonCol>
            <IonCol size='12' sizeMd='12'  sizeLg='3' sizeXl='2'>
              {/* <IonButton expand='block' onClick={addUser} color='success'>
                  <IonIcon slot='start' icon={duplicateOutline}></IonIcon>
                  Crear cuenta
              </IonButton> */}
            </IonCol>
          </IonRow>
        </form>
        </IonCardContent>
        </IonCard>
        {!loadingAccountList ? (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle className='bold' color='primary'>Lista de cuentas:</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <DataTable dense striped  data={rowsTable}  pagination
              columns={columns} />
            </IonCardContent>
        </IonCard>
        ) : <></>
        }
        <IonAlert
        isOpen={isAlertOpen}
        header='¡No hay resultados!'
        message='Para el número de cuenta ingresado no se encontraron resultados, intenta con otro.'
        buttons={[
          {
            text: 'Entiendo',
            role: 'cancel',
            handler: ()=> {
              setAlertIsOpen(false)
            }
          },
        ]}
      ></IonAlert>
    </>
    );
    };

    export default SaldodeInversionModulo;
