import axios from "axios";
import {
	allToggleDictionaryValuesAreTrue,
	setToggleDictionaryValues,
	ToggleDictionary,
	toggleDictionaryHasAtLeastOneSet,
	toggleDictionaryValues
} from "./toggleDictionary";
import { obtainURLBackendClay } from "./obtainURL";
import { createHeader } from "./auth";
import { DetailsInvestmentAccount } from "../interfaces/investmentAccounts";
export enum ScreenTypes {
	None,
	Login,
	Registration,
	PasswordRecovery,
	RegistrationConfirmation,
	Fincloud,
	Buk,
	BukFiles,
	FincloudSAP,
	BukSAP,
	UserAdmin,
	NewUser,
	EditUser,
	Contract,
	trabajo,
	AperturaEmpresas,
	VistaControl,
	AperturaEmpresasMontos,
	ImportacionClay,
	InversionesModulo,
	SaldodeInversionModulo, // por lucia
	CuentaInversionDetail, //pagina detalle inversiones
	createAccount,
	editAccount,
	deleteAccount,
	account,

}

export enum Permission {
	ADMIN,
	FINCLOUD,
	BUK,
	CREDENCIALES_SAP,
	CONTABILIDAD,
	FINANZAS
}

type PermissionDict = {
	[key in Permission]: string
}

export const permissionInformation: PermissionDict = {
	[Permission.ADMIN]: 'Administrador del sistema, provee todos los permisos',
	[Permission.FINCLOUD]: 'Tiene acceso al menu de Fincloud',
	[Permission.BUK]: 'Provee acceso al menu de Buk',
	[Permission.CREDENCIALES_SAP]: 'Provee acceso a la edición de las credenciales SAP del usuario',
	[Permission.CONTABILIDAD]: 'Provee acceso al modulo de contabilidad ',
	[Permission.FINANZAS]: 'Provee acceso al modulo de finanzas',
};

export default permissionInformation;

export type LoginData = {
	email: string,
	token: string,
}

export interface EmpresasDictionary {
	[key: string]: string;
}

export interface bancosDictionary {
	[key: string]: string;
}

interface ToggleDictionaryDictionary {
	[key: string]: ToggleDictionary;
}

interface toggleDictionaryFlags {
	[key: string]: boolean;
}

export type AppState = {
	reprocessing_status: boolean,
	loggedIn: boolean,
	screen: ScreenTypes,
	confirmationCode: string,
	confirmationEmail: string,
	email: string,
	token: string,
	sapCredentials: boolean,
	sapCredentialsEmail: string,
	permissions: Permission[],
	showModal: boolean,
	modalMessage: string,
	toggleDictionaries: ToggleDictionaryDictionary,
	toggleAllSet: toggleDictionaryFlags,
	toggleAtLeastOneSet: toggleDictionaryFlags,
	empresas: EmpresasDictionary,
	investmentAccountDetails: DetailsInvestmentAccount,
	//investmentInversionDetails :DetailsInvestmentAccount
};

export type AppStateAction = {
	type: AppStateReducerActionTypes;
	login_payload?: LoginData;
	permissions_payload?: Permission[];
	confirmation_payload?: { code: string, email: string };
	reprocessing_payload?: {status: boolean};
	error_payload?: string;
	toggle_item_payload?: { key: string, empresa: string, id: string };
	toggle_all_payload?: { key: string, value: boolean };
	toggle_payload?: { key: string };
	empresas_payload?: EmpresasDictionary;
	bancos_payload?: bancosDictionary;
	sap_credentials_payload?: boolean;
	edit_sap_credentials_payload?: string;
	investmentAccountDetails_payload?: DetailsInvestmentAccount
};

export enum AppStateReducerActionTypes {
	LOGIN,
	LOGOUT,
	REGISTER,
	REPROCESSING,
	RECOVER_PASSWORD,
	CONFIRM_REGISTRATION,
	SHOW_FINCLOUD,
	SHOW_BUK,
	SHOW_BUK_FILES,
	SHOW_SAP_FINCLOUD,
	SHOW_SAP_BUK,
	SHOW_USER_ADMIN,
	ADD_USER_ADMIN,
	EDIT_USER_ADMIN,
	SET_USER_PERMISSIONS,
	ERROR_ALERT,
	CLOSE_ALERT,
	TOGGLE_ALL,
	TOGGLE_TRUE,
	TOGGLE_ADD_ITEM,
	TOGGLE_REMOVE_ITEM,
	TOGGLE_CLEAR,
	SAVE_EMPRESAS,
	SAVE_BANCOS,
	SHOW_CONTRACT,
	SHOW_TRABAJO,
	SET_SAP_CREDENTIALS_STATUS,
	SHOW_APERTURA_EMPRESAS,
	SHOW_VISTA_CONTROL,
	SHOW_APERTURA_EMPRESAS_MONTOS,
	SHOW_IMPORTACION_CLAY,
	SHOW_INVERSIONES,
	SHOW_SALDOS_IVERSION,// agregado por lu
	CALL_INVERSION_DETAILS,
	ADD_ACCOUNT_INVERSIONES,
	EDIT_ACCOUNT_INVERSIONES,
	DELETE_ACCOUNT_INVERSIONES,
	ACCOUNT_INVERSIONES,
	CALL_ACCOUNT_DETAILS,
	CUENTAS_INVERSIONES
}

export const appStateReducer =  (
	state: AppState,
	action: AppStateAction
): AppState => {
	let currentState: AppState = { ...state };

	switch (action.type) {
		case AppStateReducerActionTypes.LOGIN:
			if (action.login_payload?.token) {
				currentState.loggedIn = true;
				currentState.screen = ScreenTypes.None;
				currentState.email = action.login_payload.email;
				currentState.token = action.login_payload.token;
			}
			break;
		case AppStateReducerActionTypes.LOGOUT:
			currentState.loggedIn = false;
			currentState.screen = ScreenTypes.None;
			currentState.permissions = [];
			break;
		case AppStateReducerActionTypes.SHOW_FINCLOUD:
			currentState.screen = ScreenTypes.Fincloud;
			break;
		case AppStateReducerActionTypes.SHOW_BUK:
			currentState.screen = ScreenTypes.Buk;
			break;
		case AppStateReducerActionTypes.SHOW_BUK_FILES:
			currentState.screen = ScreenTypes.BukFiles;
			break;
		case AppStateReducerActionTypes.SHOW_SAP_FINCLOUD:
			currentState.screen = ScreenTypes.FincloudSAP;
			break;
		case AppStateReducerActionTypes.SHOW_SAP_BUK:
			currentState.screen = ScreenTypes.BukSAP;
			break;
		case AppStateReducerActionTypes.SHOW_USER_ADMIN:
			currentState.screen = ScreenTypes.UserAdmin;
			break;
		case AppStateReducerActionTypes.SHOW_CONTRACT:
			currentState.screen = ScreenTypes.Contract;
			break;
		case AppStateReducerActionTypes.SHOW_TRABAJO:
			currentState.screen = ScreenTypes.trabajo;
			break;
		case AppStateReducerActionTypes.ADD_USER_ADMIN:
			currentState.screen = ScreenTypes.NewUser;
			break;
		case AppStateReducerActionTypes.SHOW_APERTURA_EMPRESAS:
			currentState.screen = ScreenTypes.AperturaEmpresas;
			break;
		case AppStateReducerActionTypes.SHOW_VISTA_CONTROL:
			currentState.screen = ScreenTypes.VistaControl;
			break;
		case AppStateReducerActionTypes.SHOW_APERTURA_EMPRESAS_MONTOS:
			currentState.screen = ScreenTypes.AperturaEmpresasMontos;
			break;
	    case AppStateReducerActionTypes.SHOW_IMPORTACION_CLAY:
			currentState.screen = ScreenTypes.ImportacionClay;
			break;
		case AppStateReducerActionTypes.SHOW_INVERSIONES:
			currentState.screen = ScreenTypes.InversionesModulo;
			break;
		case AppStateReducerActionTypes.SHOW_SALDOS_IVERSION: //add lu
			currentState.screen = ScreenTypes.SaldodeInversionModulo;
			break;	
		case AppStateReducerActionTypes.ADD_ACCOUNT_INVERSIONES:
			currentState.screen = ScreenTypes.createAccount;
			break;
		case AppStateReducerActionTypes.EDIT_ACCOUNT_INVERSIONES:
			currentState.screen = ScreenTypes.editAccount;
			break;
		case AppStateReducerActionTypes.DELETE_ACCOUNT_INVERSIONES:
			currentState.screen = ScreenTypes.deleteAccount;
			break;
		case AppStateReducerActionTypes.CUENTAS_INVERSIONES:
			currentState.screen = ScreenTypes.CuentaInversionDetail;
			break;
			case AppStateReducerActionTypes.ACCOUNT_INVERSIONES:
				currentState.screen = ScreenTypes.account;
				break;		
	    case AppStateReducerActionTypes.CALL_ACCOUNT_DETAILS:
			currentState.investmentAccountDetails = action.investmentAccountDetails_payload!
		
			break;
			case AppStateReducerActionTypes.CALL_INVERSION_DETAILS: // para llamar a los saldos de la inversion
				currentState.investmentAccountDetails = action.investmentAccountDetails_payload!
			// currentState.screen = ScreenTypes.CuentaInversionDetail;
				break;	
		case AppStateReducerActionTypes.EDIT_USER_ADMIN:
			currentState.screen = ScreenTypes.EditUser;
			if (action.edit_sap_credentials_payload) {
				currentState.sapCredentialsEmail = action.edit_sap_credentials_payload;
			} else {
				currentState.sapCredentialsEmail = '';
			}
			break;
		case AppStateReducerActionTypes.REPROCESSING:
			currentState.reprocessing_status = action.reprocessing_payload?.status || false;
			break;
		case AppStateReducerActionTypes.REGISTER:
			currentState.screen = ScreenTypes.Registration;
			break;
		case AppStateReducerActionTypes.RECOVER_PASSWORD:
			currentState.screen = ScreenTypes.PasswordRecovery;
			break;
		case AppStateReducerActionTypes.CONFIRM_REGISTRATION:
			currentState.screen = ScreenTypes.RegistrationConfirmation;
			currentState.confirmationCode = action.confirmation_payload?.code || '';
			currentState.confirmationEmail = action.confirmation_payload?.email || '';
			break;
		case AppStateReducerActionTypes.SET_USER_PERMISSIONS:
			if (action.permissions_payload) {
				currentState.permissions = action.permissions_payload;
			} else {
				currentState.permissions = [];
			}
			break;
		case AppStateReducerActionTypes.ERROR_ALERT:
			if (action.error_payload) {
				currentState.showModal = true;
				currentState.modalMessage = action.error_payload;
			}
			break;
		case AppStateReducerActionTypes.CLOSE_ALERT:
			currentState.showModal = false;
			break;
		case AppStateReducerActionTypes.TOGGLE_ALL:
			if (action.toggle_all_payload !== undefined) {
				const key = action.toggle_all_payload.key;
				const value = action.toggle_all_payload.value;
				if (!currentState.toggleDictionaries[key]) {
					currentState.toggleDictionaries[key] = {}
				}
				currentState.toggleDictionaries[key] = toggleDictionaryValues(currentState.toggleDictionaries[key], value);
				currentState.toggleAllSet[key] = value;
				currentState.toggleAtLeastOneSet[key] = value;
			}
			break;
		case AppStateReducerActionTypes.TOGGLE_ADD_ITEM:
			if (action.toggle_item_payload) {
				const key = action.toggle_item_payload.key;
				const empresa = action.toggle_item_payload.empresa;
				const id = action.toggle_item_payload.id;
				if (!currentState.toggleDictionaries[key]) {
					currentState.toggleDictionaries[key] = {}
				}
				if (!currentState.toggleDictionaries[key][empresa]) {
					currentState.toggleDictionaries[key][empresa] = {};
				}
				currentState.toggleDictionaries[key][empresa][id] = true;
				currentState.toggleAllSet[key] = allToggleDictionaryValuesAreTrue(currentState.toggleDictionaries[key]);
				currentState.toggleAtLeastOneSet[key] = true;
			}
			break;
		case AppStateReducerActionTypes.TOGGLE_REMOVE_ITEM:
			if (action.toggle_item_payload) {
				const key = action.toggle_item_payload.key;
				const empresa = action.toggle_item_payload.empresa;
				const id = action.toggle_item_payload.id;
				if (!currentState.toggleDictionaries[key]) {
					currentState.toggleDictionaries[key] = {}
				}
				if (!currentState.toggleDictionaries[key][empresa]) {
					currentState.toggleDictionaries[key][empresa] = {};
				}
				currentState.toggleDictionaries[key][empresa][id] = false;
				currentState.toggleAllSet[key] = false;
				currentState.toggleAtLeastOneSet[key] = toggleDictionaryHasAtLeastOneSet(currentState.toggleDictionaries[key]);
			}
			break;
		case AppStateReducerActionTypes.TOGGLE_CLEAR:
			if (action.toggle_payload) {
				const key = action.toggle_payload.key;
				if (!currentState.toggleDictionaries[key]) {
					currentState.toggleDictionaries[key] = {}
				}
				currentState.toggleDictionaries[key] = {};
				currentState.toggleAtLeastOneSet[key] = false;
			}
			break;
		case AppStateReducerActionTypes.TOGGLE_TRUE:
			if (action.toggle_payload) {
				const key = action.toggle_payload.key;
				if (!currentState.toggleDictionaries[key]) {
					currentState.toggleDictionaries[key] = {}
				}
				currentState.toggleDictionaries[key] = setToggleDictionaryValues(currentState.toggleDictionaries[key]);
				currentState.toggleAllSet[key] = true;
				currentState.toggleAtLeastOneSet[key] = true;
			}
			break;
		case AppStateReducerActionTypes.SAVE_EMPRESAS:
			if (action.empresas_payload) {
				currentState.empresas = action.empresas_payload;
			}
			break;
		case AppStateReducerActionTypes.SET_SAP_CREDENTIALS_STATUS:
			if (action.sap_credentials_payload !== undefined) {
				currentState.sapCredentials = action.sap_credentials_payload;
				localStorage.setItem("sapcredentials", JSON.stringify(currentState.sapCredentials));
			}
			break;
		default:
			console.log('unknown action [' + action.type + ']');
	}

	localStorage.setItem("screen", JSON.stringify(currentState.screen));

	return { ...state, ...currentState };
};
