import { useReducer } from 'react';
import WaitingReprocessing from './components/auth/waitingReprocessing';
import './App.css';
import { DetailsInvestmentAccount } from './components/interfaces/investmentAccounts';
import MainSite from './components/site/main';
import MainLogIn from './components/auth/main';
import {
  AppState,
  appStateReducer,
  AppStateReducerActionTypes,
  ScreenTypes,
} from './components/common/reducer';
import AppContext from './components/common/appContext';
import ResetPassword from './components/auth/resetPassword';
import Modal from 'react-modal';
import { IonApp, setupIonicReact } from '@ionic/react';
//import '@ionic/react/css/normalize.css';
import '@ionic/react/css/core.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

//config Ionic
setupIonicReact({
  mode: 'md',
});
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

// Modal styles
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0px',
    background: 'rgba(0,0,0,0)',
  },
  overlay: {
    background: 'rgba(0,0,0,0.75)',
  },
};

function getCurrentState() {
  const initialState: AppState = {
    reprocessing_status: false,
    loggedIn: false,
    screen: ScreenTypes.None,
    permissions: [],
    confirmationCode: '',
    confirmationEmail: '',
    email: '',
    token: '',
    sapCredentials: false,
    sapCredentialsEmail: '',
    showModal: false,
    modalMessage: '',
    toggleDictionaries: {},
    toggleAllSet: {},
    toggleAtLeastOneSet: {},
    empresas: {},
    investmentAccountDetails: {},
   // investmentInversionDetails: {}
  };

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const screen = JSON.parse(localStorage.getItem('screen') || '0');
  const sapcredentials = JSON.parse(localStorage.getItem('sapcredentials') || 'false');

  if (user && user.email) {
    initialState.loggedIn = true;
    initialState.permissions = user.permisos;
    initialState.email = user.email;
    initialState.token = user.token;
    initialState.screen = screen;
    initialState.sapCredentials = sapcredentials;
  }

  return initialState;
}

function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [appState, appDispatch] = useReducer(appStateReducer, getCurrentState());
  const providerState = {
    state: appState,
    dispatch: appDispatch,
  };

  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  let page = (
    <>
      <MainLogIn />
    </>
  );

  if (params.has('reset')) {
    const reset = params.get('reset');
    console.log('reset [' + reset + ']');
    page = (
      <>
        <section id="mainPage">
          <header></header>
          <main>
            <ResetPassword codigo={reset} email={params.get('email')} />
          </main>
        </section>
      </>
    );
  } else if (params.has('reprocessing')) {
    page = (
      <>
       <WaitingReprocessing />
      </>
    );
  } else if (params.has('confirmation')) {
    const confirmation = params.get('confirmation');
    console.log('confirmation [' + confirmation + ']');
    page = (
      <>
        <MainLogIn codigo={confirmation} email={params.get('email')} />
      </>
    );
  } else if (appState.loggedIn) {
    page = (
      <>
        <MainSite />
      </>
    );
  }

  function closeModal() {
    appDispatch({ type: AppStateReducerActionTypes.CLOSE_ALERT});
  }

  return (
    <>
      <IonApp>
        <Modal
          isOpen={appState.showModal}
          onAfterOpen={() => { }}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel={appState.modalMessage}>
          <button onClick={closeModal} className="modalButton">
            <div className="modalInside">
              <div className="modalText">
                <p>{appState.modalMessage}</p>
              </div>
              <button onClick={closeModal} className="colorD standardSize">
                Entendido
              </button>
            </div>
          </button>
        </Modal>
        <AppContext.Provider value={providerState}>{page}</AppContext.Provider>
      </IonApp>
    </>
  );
}

export default App;
